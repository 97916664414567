import { controlPanelActions, securityDetailsActions } from '../../../../../../../actions';
import { useAppSelector } from '../../../../../../../effects/useAppSelector';
import { BwicPositionBase } from '../../../../../../../types/bwic/BwicPositionBase';
import { RightSideTabPanel } from '../../../../../../controls/RightSideTabPanel';
import { SecurityDetails, SecurityDetailsPanel } from '../../../../../../security-details';
import SecurityDetailsOptions from '../../../../../../security-details/SecurityDetailsOptions';
import { BWICLog } from '../../../../../../security-details/bwic-log/BWICLog';
import { bwicLogActions } from '../../../../../../../actions/bwic-log.actions';
import { useAppDispatch } from '../../../../../../../effects/useAppDispatch';
import { DealSecurity } from '../../../../../../../types/security/DealSecurityStatistics';

interface Props {
    security?: DealSecurity | null
    onClose: () => void
}
export const PreviousTransactionControlPanel = ({ security, onClose }: Props) => {
    const dispatch = useAppDispatch();

    const securityDetails = useAppSelector(s => s.securityDetails);
    const bwicLog = useAppSelector(s => s.bwicLog);

    if (!security) return null;

    const currentBwic = security?.currentBwic;

    if (!currentBwic) return <SecurityDetailsPanel onClose={onClose} />;

    const tabs = [{
            title: 'Security',
            active: !!securityDetails.security,
            renderContent: () => <SecurityDetails />,
            onActivate: () => { security && dispatch(securityDetailsActions.securityDetailsInitRequest(security as BwicPositionBase, true)) },
            onDeactivate: () => { dispatch(securityDetailsActions.securityDetailsReset()) }
        }, {
            title: 'BWIC Log',
            active: !!bwicLog.security,
            renderContent: () => <BWICLog />,
            onActivate: () => { security && dispatch(bwicLogActions.bwicLogInitRequest(security as BwicPositionBase)) },
            onDeactivate: () => dispatch(bwicLogActions.bwicLogReset())
        }
    ];

    return (
        <RightSideTabPanel
            className="right-panel-tabs"
            title={
                <>
                    <>{security?.ticker}</>
                    {security && (
                        <SecurityDetailsOptions
                            optionsExclude={[]}
                            position={security as BwicPositionBase}
                        />
                    )}
                </>
            }
            items={tabs}
            onClose={() => {
                dispatch(controlPanelActions.hide());
                onClose()
            }}
        />
    )
};
