import { useState } from "react";
import { constants } from "../../../../../../../constants";
import { usePreviousTransaction } from "../../../../../../../effects/amr/usePreviousTransaction";
import { TransactionType } from "../../../../../../../types/amr-pipeline/enums/TransactionType";
import { OriginatingTransaction } from "../../../../../../../types/amr-pipeline/models/OriginatingTransaction";
import { OriginatingTransactionClass } from "../../../../../../../types/amr-pipeline/models/OriginatingTransactionClass";
import { SubscriptionFeature } from "../../../../../../../types/billing/SubscriptionFeature";
import { DealSecurity, DealSecurityStatistics } from "../../../../../../../types/security/DealSecurityStatistics";
import { user } from "../../../../../../../user";
import { isRequestSuccess } from "../../../../../../../utils";
import { RequiredFeature } from "../../../../../../access/RequiredFeature";
import { Table } from "../../../../../../bidding/common/table";
import { ColumnBuilder } from "../../../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { IColumnDefinition } from "../../../../../../bidding/common/table/types/ColumnDefinition";
import { useDealSecurityStatistics } from "../../../../../../clo-managers/tabs/deals/hooks";
import { EmptyPlaceholder, Preloader, TickerButton } from "../../../../../../common";
import { currentBwicColumn, inventoryColumn, lastTradedColumn, lastTradedColorColumn, lastTradedMineColumn, myBwicColumn } from "../../../../../aggregated/arranger-pipeline/arranger-pipeline.columns";
import { SectionContent } from "../../../../../common/section/SectionContent";
import { PreviousTransactionControlPanel } from "./PreviousTransactionControlPanel";

interface Props {
    transaction: OriginatingTransaction
}

export function PreviousTransaction({ transaction }: Props) {
    if ((transaction.type !== TransactionType.refi && transaction.type !== TransactionType.reset) ||
        !transaction.dealTicker) {
        return null;
    }

    return (
        <SectionContent
            title="Capital Structure - Previous Transaction"
            className="data-item-previous-transaction"
        >
            <RequiredFeature feature={SubscriptionFeature.IssuanceMonitorFullAccess}>
                {
                    user.hasFeatures(SubscriptionFeature.IssuanceMonitorFullAccess) &&
                    <PreviousTransactionTable transaction={transaction} />
                }
            </RequiredFeature>
        </SectionContent>

    );
}

interface PreviousTransactionTableDataItem extends OriginatingTransactionClass {
    security?: DealSecurity
}

const convertToTableDataItem = (_class: OriginatingTransactionClass, statistics?: DealSecurityStatistics) => {
    const security = statistics?.classes.find(s =>
        (s.name ?? '').localeCompare(_class.name, undefined, { sensitivity: 'accent' }) === 0);

    return {
        ..._class,
        security
    };
}

function PreviousTransactionTable({ transaction }: Props) {
    const { requestState, statistics } = useDealSecurityStatistics([transaction.dealTicker!]);
    const { previousTransaction, previousTransactionRequestState } = usePreviousTransaction(transaction.dealReferenceName, transaction.referenceName);

    const [selectedSecurity, setSelectedSecurity] = useState<DealSecurity | null>(null);

    if (previousTransaction == null && isRequestSuccess(previousTransactionRequestState)) {
        return null;
    }

    const columns: IColumnDefinition<PreviousTransactionTableDataItem>[] = [{
        columnKey: "className",
        renderColumnHeaderContent: () => "Class",
        renderColumnContent: _class => _class.name,
    }, {
        columnKey: "ticker",
        renderColumnHeaderContent: () => "Ticker(144A)",
        renderColumnContent: _class => _class.security
            ? <TickerButton
                security={_class.security}
                onClick={(e: MouseEvent, security: DealSecurity | null) => setSelectedSecurity(security)}
            /> : (_class.tranche?.ticker144A || constants.emptyPlaceholder),
            className: "data-list-cell-lg",
    },
        myBwicColumn,
        currentBwicColumn,
        inventoryColumn,
        lastTradedMineColumn,
        lastTradedColumn,
        lastTradedColorColumn
    ];

    return (
        <Preloader inProgress={!isRequestSuccess(requestState) || !isRequestSuccess(previousTransactionRequestState)}>
            {previousTransaction?.classes.length ?
                <>
                    <Table
                        columns={columns.map(c => new ColumnBuilder(c))}
                        dataItems={previousTransaction.classes.map(c => convertToTableDataItem(c, statistics[0]))}
                        className="data-list-striped"
                    />
                    <PreviousTransactionControlPanel security={selectedSecurity} onClose={() => setSelectedSecurity(null)} />
                </>
                : <EmptyPlaceholder />
            }
        </Preloader>
    );
}